import { TransportState } from '../reducer/Transport';

export enum TransportAction {
  UPDATE_TRANSPORT = 'UPDATE_TRANSPORT',
}

export interface IAppAction {
  type: TransportAction;
}

export interface IUpdateTransportAction extends IAppAction {
  type: TransportAction.UPDATE_TRANSPORT;
  value: TransportState;
}
