import { ResultOfferState, TotsOfferState } from '../reducer/Tots';

export enum TotsAction {
  UPDATE_TOTS = 'UPDATE_TOTS',
  UPDATE_RESULTS = 'UPDATE_RESULTS',
}

export interface IAppAction {
  type: TotsAction;
}

export interface IUpdateTotsOfferAction extends IAppAction {
  type: TotsAction.UPDATE_TOTS;
  value: TotsOfferState;
}

export interface IUpdateResultOfferAction extends IAppAction {
  type: TotsAction.UPDATE_RESULTS;
  value: ResultOfferState;
}
