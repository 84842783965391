import { PrixHubState } from '../reducer/PrixHub';

export enum PrixHubAction {
  UPDATE_PRIX = 'UPDATE_PRIX',
}

export interface IAppAction {
  type: PrixHubAction;
}

export interface IUpdatePrixHubAction extends IAppAction {
  type: PrixHubAction.UPDATE_PRIX;
  value: PrixHubState;
}
