import { PagesState } from '../reducer/Pages';

export enum PagesAction {
  UPDATE_PAGE = 'UPDATE_PAGE',
  ACCESS_ERROR = 'ACCESS_ERROR',
}

export interface IAppAction {
  type: PagesAction;
}

export interface IUpdatePagesAction extends IAppAction {
  type: PagesAction.UPDATE_PAGE;
  value: PagesState;
}
export interface IAccessErrorAction extends IAppAction {
  type: PagesAction.ACCESS_ERROR;
  value: boolean;
}
