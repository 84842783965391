export class Middleware {
  private static isAuthorized: boolean = false;
  private static user: any;

  protected static setAuthorization = (bool: boolean): boolean => {
    Middleware.isAuthorized = bool;
    return bool;
  };

  protected static getAuthorization = (): boolean => {
    return Middleware.isAuthorized;
  };

  protected static getUser = (): any => {
    return Middleware.user;
  };

  protected static CheckConnection = async (): Promise<boolean> => {
    let res = false;
    if (localStorage.getItem('authorized-user') !== null) {
      const local: string = localStorage.getItem('authorized-user')!;
      Middleware.user = JSON.parse(local);

      if (Middleware.user.token !== undefined) {
        const header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('authorization', 'Bearer ' + Middleware.user.token);

        await fetch(process.env.REACT_APP_BASE_API + '/api/v1.0/User/Ping', {
          method: 'GET',
          headers: header,
        })
          .then(d => {
            if (d.status === 200) {
              res = true;
            } else {
              res = false;
              Middleware.user = undefined;
              Middleware.isAuthorized = false;
            }
          })
          .catch(() => {
            res = false;
            Middleware.user = undefined;
            Middleware.isAuthorized = false;
          });
      }
    } else {
      Middleware.user = undefined;
      Middleware.isAuthorized = false;
    }

    return res;
  };
}
