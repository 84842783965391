import AdminMiddleware from '../../middleware/AdminMiddleware';
import UserMiddleware from '../../middleware/UserMiddleware';
import PartnerMiddleware from '../../middleware/PartnerMiddleware';
import AnonymousMiddleware from '../../middleware/AnonymousMiddleware';
import EditorMiddleware from '../../middleware/EditorMiddleware';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IAccessErrorAction, PagesAction } from '../../store/action/Pages';

const ViewMiddleware = (props: any) => {
  const { middleware, children }: { middleware: Array<string>; children: any } = props;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const fnExec: { [key: string]: () => Promise<boolean> } = {
    admin: AdminMiddleware.Run,
    user: UserMiddleware.Run,
    partner: PartnerMiddleware.Run,
    editor: EditorMiddleware.Run,
    anonymous: AnonymousMiddleware.Run,
  };
  const [res, setRes] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function getRes(): Promise<boolean> {
      if (middleware !== undefined) {
        for (let middle of middleware) {
          if (await fnExec[middle]()) {
            return true;
          }
        }
      }
      return false;
    }
    getRes()
      .then(value => {
        setRes(value);
      })
      .finally(() => setLoaded(true));
  }, []);

  useEffect(() => {
    if (!loaded) return;
    if (res) {
      if (middleware.includes('anonymous') && location.pathname === '/') {
        history.push('/panel', 'Erreur de connexion');
      }
    } else {
      if (location.pathname !== '/') {
        history.push('/', 'Erreur de connexion');
      }
    }
    dispatch<IAccessErrorAction>({
      type: PagesAction.ACCESS_ERROR,
      value: !res,
    });
  }, [loaded, res]);

  return <>{children}</>;
};

export default ViewMiddleware;
