import { lazy } from 'react';

const routes = [
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/',
    middleware: ['anonymous'],
    component: lazy(() => import('./views/auth/Singin')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/panel',
    middleware: ['user', 'admin', 'partner'],
    component: lazy(() => import('./views/pages/Panel')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/offers',
    middleware: ['user', 'admin', 'partner'],
    component: lazy(() => import('./views/pages/offers/Index')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/prixhub',
    middleware: ['user', 'partner', 'admin'],
    component: lazy(() => import('./views/pages/PrixHub')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/users',
    middleware: ['admin'],
    component: lazy(() => import('./views/pages/users/Index')),
  },
  {
    exact: false,
    guard: undefined,
    layout: undefined,
    path: '/partner',
    middleware: ['admin'],
    component: lazy(() => import('./views/pages/partner/Index')),
  },
  {
    exact: false,
    guard: undefined,
    layout: undefined,
    path: '/users/edit',
    middleware: ['admin'],
    component: lazy(() => import('./views/pages/users/EditUser')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/users/add',
    middleware: ['admin'],
    component: lazy(() => import('./views/pages/users/AddUser')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/partners',
    middleware: ['admin'],
    component: lazy(() => import('./views/pages/partners/Index')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/partners/add',
    middleware: ['admin'],
    component: lazy(() => import('./views/pages/partners/AddPartner')),
  },
  {
    exact: false,
    guard: undefined,
    layout: undefined,
    path: '/partners/edit',
    middleware: ['admin', 'partner'],
    component: lazy(() => import('./views/pages/partners/EditPartner')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/parameters',
    middleware: ['admin', 'partner'],
    component: lazy(() => import('./views/pages/config/Index')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/admin',
    middleware: ['admin'],
    component: lazy(() => import('./views/pages/Admin')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/admin/dayoff',
    middleware: ['admin'],
    component: lazy(() => import('./views/pages/admin/DayOff')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/admin/system',
    middleware: ['admin'],
    component: lazy(() => import('./views/pages/admin/System')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/admin/network',
    middleware: ['admin'],
    component: lazy(() => import('./views/pages/admin/Network')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/admin/hub',
    middleware: ['admin'],
    component: lazy(() => import('./views/pages/admin/Hub')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/admin/transport',
    middleware: ['admin'],
    component: lazy(() => import('./views/pages/admin/Transport')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/display',
    middleware: ['user', 'admin', 'partner'],
    component: lazy(() => import('./views/pages/display/DisplayOffer')),
  },
  {
    exact: true,
    guard: undefined,
    layout: undefined,
    path: '/account',
    middleware: ['user', 'admin', 'partner'],
    component: lazy(() => import('./views/auth/Account')),
  },
];

export default routes;
