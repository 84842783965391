import { IUpdateGazAction, GazAction } from '../action/Gaz';

export interface IGazParams {

}

const initialState: GazState = {
  Column: [],
  Devise: {
    CostFixMonth: false,
    CostMwhFix: false,
    CostMwhFloat: false,
    MarkupFix: false,
    MarkupFloat: false,
    DiscountFixAn: false,
    DiscountFixMonth: false,
    DiscountFixMwh: false,
  }
};

export interface IGaz {
  MonthNumber: number;
  Month: string;
  Year: number;
  Data: {
    Sell: {
      NCG: number;
      PEG: number;
      TTF: number;
    };
    Buy: {
      NCG: number;
      PEG: number;
      TTF: number;
    };
    PercentFix: number;
    CoutGaz: {
      FixeMonth: number;
      SellFixe: number;
      SellFlottant: number;
    };
    Markup: {
      Fixe: number;
      Flottant: number;
    };
    Discount: {
      FixeAn: number;
      FixeMois: number;
      FixeMWh: number;
    };
  }
}

export interface GazState {
  Column: Array<IGaz>;
  Devise: {
    CostFixMonth: boolean;
    CostMwhFix: boolean;
    CostMwhFloat: boolean;
    MarkupFix: boolean;
    MarkupFloat: boolean;
    DiscountFixAn: boolean;
    DiscountFixMonth: boolean;
    DiscountFixMwh: boolean;
  }
}

export const GazReducer = (state: GazState = initialState, action: IUpdateGazAction): GazState => {
  switch (action.type) {
    case GazAction.UPDATE_GAZ:
      return action.value;
    default:
      return state;
  }
};
