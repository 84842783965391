import React, { Fragment } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import routes from "./routes";
import ViewMiddleware from "./views/shared/ViewMiddlware";

const App = () => {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;
            const Middleware = route.middleware || undefined;

            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={() => (
                  <Guard>
                    <Layout>
                      <ViewMiddleware middleware={Middleware}>
                        <Component />
                      </ViewMiddleware>
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
