import { IUpdatePrixHubAction, PrixHubAction } from '../action/PrixHub';

const initialState: PrixHubState = {
  Column: [],
  RefreshDate: '',
};

export interface PrixHubState {
  Column: Array<{
    MonthNumber: number;
    Month: string;
    Year: number;
    Data: {
      NCG: {
        Source: 1 | 2 | 3;
        Price: number;
      };
      PEG: {
        Source: 1 | 2 | 3;
        Price: number;
      };
      TTF: {
        Source: 1 | 2 | 3;
        Price: number;
      };
      NCGCHF?: {
        Source: 1 | 2 | 3;
        Price: number;
      };
      PEGCHF?: {
        Source: 1 | 2 | 3;
        Price: number;
      };
      TTFCHF?: {
        Source: 1 | 2 | 3;
        Price: number;
      };
    };
  }>;
  RefreshDate: string;
}

export const PrixHubReducer = (state: PrixHubState = initialState, action: IUpdatePrixHubAction): PrixHubState => {
  switch (action.type) {
    case PrixHubAction.UPDATE_PRIX:
      return action.value;
    default:
      return state;
  }
};
