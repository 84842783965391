import { CapaciteState } from '../reducer/Capacite';

export enum CapaciteAction {
  UPDATE_CAPACITE = 'UPDATE_CAPACITE',
}

export interface IAppAction {
  type: CapaciteAction;
}

export interface IUpdateCapaciteAction extends IAppAction {
  type: CapaciteAction.UPDATE_CAPACITE;
  value: CapaciteState;
}
