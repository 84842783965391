import { IUpdateTransportAction, TransportAction } from "../action/Transport";

const initialState: TransportState = {
  Column: [],
};

export interface ITransportParam {
  Tarif: {
    Nat: {
      Nat1: number;
      Nat2: number;
      Nat3: number;
    };
    Reg: {
      Reg1: number;
      Reg2: number;
      Reg3: number;
    };
    Loc: {
      Loc1F: number;
      Loc1I: number;
      Loc1Mwh: number;
      Loc1Fix: number;
      Loc2F: number;
      Loc2I: number;
      Loc2Mwh: number;
      Loc2Fix: number;
      Loc3F: number;
      Loc3I: number;
      Loc3Mwh: number;
      Loc3Fix: number;
    };
  };
  Fact: {
    Mfa: number;
    Mfm: number;
  };
  Frais: {
    Comptage: number;
    Douanes: number;
    Agents: number;
  };
  Taux: {
    Euro: number;
  };
}

export interface ITransport {
  MonthNumber: number;
  Month: string;
  Year: number;
  //Param
  Params: ITransportParam;
  Data: {
    Buy: {
      Reg: {
        P1: number;
        P2: number;
      };
      Nat: {
        P1: number;
        P2: number;
      };
      Loc: {
        P1: number;
        P2: number;
        P1I: number;
        P2I: number;
      };
    };
    Sell: {
      Reg: {
        P1: number;
        P2: number;
      };
      Nat: {
        P1: number;
        P2: number;
      };
      Loc: {
        P1: number;
        P2: number;
        P1I: number;
        P2I: number;
      };
    };
    SuppCap: number;
    Discount: number;
    DiscountNm3: number;
  };
}

export interface TransportState {
  Column: Array<ITransport>;
}

export const TransportReducer = (
  state: TransportState = initialState,
  action: IUpdateTransportAction
): TransportState => {
  switch (action.type) {
    case TransportAction.UPDATE_TRANSPORT:
      return action.value;
    default:
      return state;
  }
};
