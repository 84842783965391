import { TaxesState } from '../reducer/Taxes';

export enum TaxesAction {
  UPDATE_TAXE = 'UPDATE_TAXE',
}

export interface IAppAction {
  type: TaxesAction;
}

export interface IUpdateTaxesAction extends IAppAction {
  type: TaxesAction.UPDATE_TAXE;
  value: TaxesState;
}
