import { ConsommationState } from '../reducer/Consommation';

export enum ConsommationAction {
  UPDATE_CONSO = 'UPDATE_CONSO',
}

export interface IAppAction {
  type: ConsommationAction;
}

export interface IUpdateConsommationAction extends IAppAction {
  type: ConsommationAction.UPDATE_CONSO;
  value: ConsommationState;
}
