import { GazState } from '../reducer/Gaz';

export enum GazAction {
  UPDATE_GAZ = 'UPDATE_GAZ',
}

export interface IAppAction {
  type: GazAction;
}

export interface IUpdateGazAction extends IAppAction {
  type: GazAction.UPDATE_GAZ;
  value: GazState;
}
