import { Middleware } from './Middleware';

export default class AnonymousMiddleware extends Middleware {
  public static Run = async (): Promise<boolean> => {
    if (await Middleware.CheckConnection()) {
      AnonymousMiddleware.CheckUserAnonyme();
    }
    return Middleware.getAuthorization();
  };

  private static CheckUserAnonyme = (): void => {
    const user = Middleware.getUser();
    Middleware.setAuthorization(user !== undefined && user !== null);
  };
}
