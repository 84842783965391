import { IUpdateResultOfferAction, IUpdateTotsOfferAction, TotsAction } from '../action/Tots';

const initialState: TotsOfferState = {
  ConsoMwhMois: '0.000',
  TotQuantVendu: '0.000',
  TotQuantVenduFix: '0.000',
  TotQuantVenduFloat: '0.000',
  MargeNette: '0.000',
  MargeBruteEuro: '0.000',
  MargeBruteChf: '0.000',
  MWhHTEuro: '0.000',
  MWhHTChf: '0.000',
  MargeGazEuro: '0.000',
  MargeGazChf: '0.000',
  MargeTransEuro: '0.000',
  MargeTransChf: '0.000',
  MargeCapaEuro: '0.000',
  MargeCapaChf: '0.000',
  CoutGazBuy: '0.000',
  CoutTotGazEuro: '0.000',
  CoutTotGazChf: '0.000',
  RevTotGazEuro: '0.000',
  RevTotGazChf: '0.000',
  AutresCoutEuro: '0.000',
  AutresCoutChf: '0.000',
  VenteGazEuro: '0.000',
  VenteGazChf: '0.000',
  TotVentCapBrutEuro: '0.000',
  TotVentCapBrutChf: '0.000',
  TotVentCapNetEuro: '0.000',
  TotVentCapNetChf: '0.000',
  TotRevTransBrutEuro: '0.000',
  TotRevTransBrutChf: '0.000',
  TotRevTransNetEuro: '0.000',
  TotRevTransNetChf: '0.000',
  CoutTotTransChf: '0.000',
  CoutTotTransEuro: '0.000',
  CoutTotCapaChf: '0.000',
  CoutTotCapaEuro: '0.000',
  TotTaxeProvisiogasChf: '0.000',
  TotTaxeCoDeuxChf: '0.000',
  TotTaxeOilChf: '0.000',
  HubColumn: new Array<HubTots>(),
  YearlyQuantity: '0.000',
  MaxHourlyPeak: '0.000',
  TotEuroMoyen: '0.000',
  FirstTaxeProvisiogasChf: '0.000',
  FirstTaxeCoDeuxChf: '0.000',
  FirstTaxeOilChf: '0.000',
  TotDiscountMonth: '0.000',
  TotDiscountMonthNm: '0.000',
  TotDiscountFixMonth: '0.000',
  TotDiscoutnFixMwh: '0.000',
  ResumeCTS: {
    CostGaz: '0.000',
    CostCapa: '0.000',
    CostTrans: '0.000',
    TotFeesFree: '0.000',
    TotFeesCODeux: '0.000',
    TotFeesOil: '0.000',
    TotFeesProvisioGas: '0.000',
    TotFees: '0.000',
    TotTTC: '0.000'
  },
  ParamTransport: {
    Tarif: {
      Nat: {
        FirstNat1: '0.000',
        FirstNat2: '0.000',
        FirstNat3: '0.000'
      },
      Reg: {
        FirstReg1: '0.000',
        FirstReg2: '0.000',
        FirstReg3: '0.000'
      },
      Loc: {
        FirstLoc1F: '0.000',
        FirstLoc1I: '0.000',
        FirstLoc1Mwh: '0.000',
        FirstLoc1Fix: '0.000',
        FirstLoc2F: '0.000',
        FirstLoc2I: '0.000',
        FirstLoc2Mwh: '0.000',
        FirstLoc2Fix: '0.000',
        FirstLoc3F: '0.000',
        FirstLoc3I: '0.000',
        FirstLoc3Mwh: '0.000',
        FirstLoc3Fix: '0.000'
      }
    },
    Fact: {
      FirstMfa: '0.000',
      FirstMfm: '0.000'
    },
    Frais: {
      FirstComptage: '0.000',
      FirstDouanes: '0.000',
      FirstAgents: '0.000'
    },
    Taux: {
      FirstEuro: '0.000'
    }
  }
};

const initiResultState: ResultOfferState = {
  MWhEuro: '0.000',
  MWhChf: '0.000',
  GazEuro: '0.000',
  GazChf: '0.000',
  GazNuEuro: '0.000',
  GazNuChf: '0.000',
  AutresEuro: '0.000',
  AutresChf: '0.000',
  CapaChf: '0.000',
  CapaEuro: '0.000',
  TransChf: '0.000',
  TransEuro: '0.000',
  MargeGazEuro: '0.000',
  MargeGazChf: '0.000',
  MargeCapaEuro: '0.000',
  MargeCapaChf: '0.000',
  MargeTransEuro: '0.000',
  MargeTransChf: '0.000',
  MargeTotalEuro: '0.000',
  MargeTotalChf: '0.000',
  SellGazCapaChf: '0.000',
};

export interface HubTots {
  PEG: string;
  NCG: string;
  TTF: string;
}

export interface TotsOfferState {
  ConsoMwhMois: string;
  TotQuantVendu: string;
  TotQuantVenduFix: string;
  TotQuantVenduFloat: string;
  MargeNette: string;
  MargeBruteEuro: string;
  MargeBruteChf: string;
  MWhHTEuro: string;
  MWhHTChf: string;
  MargeGazEuro: string;
  MargeGazChf: string;
  MargeTransEuro: string;
  MargeTransChf: string;
  MargeCapaEuro: string;
  MargeCapaChf: string;
  CoutGazBuy: string;
  RevTotGazEuro: string;
  RevTotGazChf: string;
  AutresCoutEuro: string;
  AutresCoutChf: string;
  TotVentCapBrutEuro: string;
  TotVentCapBrutChf: string;
  TotVentCapNetEuro: string;
  TotVentCapNetChf: string;
  TotRevTransBrutEuro: string;
  TotRevTransBrutChf: string;
  TotRevTransNetEuro: string;
  TotRevTransNetChf: string;
  VenteGazEuro: string;
  VenteGazChf: string;
  CoutTotGazEuro: string;
  CoutTotGazChf: string;
  CoutTotTransChf: string;
  CoutTotTransEuro: string;
  CoutTotCapaChf: string;
  CoutTotCapaEuro: string;
  TotTaxeProvisiogasChf: string;
  TotTaxeCoDeuxChf: string;
  TotTaxeOilChf: string;
  YearlyQuantity: string;
  MaxHourlyPeak: string;
  TotEuroMoyen: string;
  FirstTaxeProvisiogasChf: string;
  FirstTaxeCoDeuxChf: string;
  FirstTaxeOilChf: string;
  TotDiscountMonth: string;
  TotDiscountMonthNm: string;
  TotDiscountFixMonth: string;
  TotDiscoutnFixMwh: string;
  ResumeCTS: {
    CostGaz: string;
    CostCapa: string;
    CostTrans: string;
    TotFeesFree: string;
    TotFeesCODeux: string;
    TotFeesOil: string;
    TotFeesProvisioGas: string;
    TotFees: string;
    TotTTC: string;
  };
  ParamTransport: {
    Tarif: {
      Nat: {
        FirstNat1: string;
        FirstNat2: string;
        FirstNat3: string;
      };
      Reg: {
        FirstReg1: string;
        FirstReg2: string;
        FirstReg3: string;
      };
      Loc: {
        FirstLoc1F: string;
        FirstLoc1I: string;
        FirstLoc1Mwh: string;
        FirstLoc1Fix: string;
        FirstLoc2F: string;
        FirstLoc2I: string;
        FirstLoc2Mwh: string;
        FirstLoc2Fix: string;
        FirstLoc3F: string;
        FirstLoc3I: string;
        FirstLoc3Mwh: string;
        FirstLoc3Fix: string;
      };
    };
    Fact: {
      FirstMfa: string;
      FirstMfm: string;
    };
    Frais: {
      FirstComptage: string;
      FirstDouanes: string;
      FirstAgents: string;
    };
    Taux: {
      FirstEuro: string;
    };
  };
  HubColumn: Array<HubTots>;
}

export interface ResultOfferState {
  MWhEuro: string;
  MWhChf: string;
  GazEuro: string;
  GazChf: string;
  GazNuEuro: string;
  GazNuChf: string;
  CapaChf: string;
  CapaEuro: string;
  TransChf: string;
  TransEuro: string;
  AutresEuro: string;
  AutresChf: string;
  MargeGazEuro: string;
  MargeGazChf: string;
  MargeCapaEuro: string;
  MargeCapaChf: string;
  MargeTransEuro: string;
  MargeTransChf: string;
  MargeTotalEuro: string;
  MargeTotalChf: string;
  SellGazCapaChf: string;
}

export const TotsReducer = (state: TotsOfferState = initialState, action: IUpdateTotsOfferAction): TotsOfferState => {
  switch (action.type) {
    case TotsAction.UPDATE_TOTS:
      return action.value;
    default:
      return state;
  }
};

export const ResultReducer = (state: ResultOfferState = initiResultState, action: IUpdateResultOfferAction): ResultOfferState => {
  switch (action.type) {
    case TotsAction.UPDATE_RESULTS:
      return action.value;
    default:
      return state;
  }
};
