import { Middleware } from './Middleware';

export default class EditorMiddleware extends Middleware {
  public static _ROLE = 'Editor';

  public static Run = async (): Promise<boolean> => {
    if (await Middleware.CheckConnection()) {
      EditorMiddleware.CheckUserRole();
    }

    return Middleware.getAuthorization();
  };

  private static CheckUserRole = (): void => {
    const user = Middleware.getUser();

    Middleware.setAuthorization(user !== null && user !== undefined && user.roles.includes(EditorMiddleware._ROLE));
  };
}
