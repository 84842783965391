import { TypeOffreState } from '../reducer/TypeOffre';

export enum TypeOffreAction {
  UPDATE_TYPE = 'UPDATE_TYPE',
}

export interface IAppAction {
  type: TypeOffreAction;
}

export interface IUpdateTypeOffreAction extends IAppAction {
  type: TypeOffreAction.UPDATE_TYPE;
  value: TypeOffreState;
}
