import { IUpdateTaxesAction, TaxesAction } from '../action/Taxes';

export const initialState: TaxesState = {
  PriceStartHour: {
    Name: 'PriceStartHour',
    Value: '10:00',
    Display: 'Heure de début',
  },
  PriceEndHour: {
    Name: 'PriceEndHour',
    Value: '16:00',
    Display: 'Heure de fin',
  },
  PriceValidity: {
    Name: 'PriceValidity',
    Value: 15,
    Display: 'Durée de la validité des prix (minutes)',
  },
  FeeCo2: {
    Name: 'FeeCo2',
    Value: 21.69,
    Display: 'Taxe CO2',
  },
  FeeOil: {
    Name: 'FeeOil',
    Value: 0.142,
    Display: 'Taxe Huiles Minérales',
  },
  FeeProvisiogas: {
    Name: 'FeeProvisiogas',
    Value: 0.17,
    Display: 'Taxe Provisiogas',
  },
  PriceC: {
    Name: 'PriceC',
    Value: 0.15,
    Display: 'Prix C (%)',
  },
  PriceCovercost: {
    Name: 'PriceCovercost',
    Value: 3,
    Display: 'Prix C Surcoût Gaz',
  },
};

type TaxesObject = {
  Name: string;
  Value: string | number;
  Display: string;
};

export interface TaxesState {
  PriceStartHour: TaxesObject;
  PriceEndHour: TaxesObject;
  PriceValidity: TaxesObject;
  FeeCo2: TaxesObject;
  FeeOil: TaxesObject;
  FeeProvisiogas: TaxesObject;
  PriceC: TaxesObject;
  PriceCovercost: TaxesObject;
}

export const TaxesReducer = (state: TaxesState = initialState, action: IUpdateTaxesAction): TaxesState => {
  switch (action.type) {
    case TaxesAction.UPDATE_TAXE:
      return action.value;
    default:
      return state;
  }
};
