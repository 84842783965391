import { combineReducers, createStore } from 'redux';
import { TypeOffreState, TypeOffreReducer } from './reducer/TypeOffre';
import { ConsommationReducer, ConsommationState } from './reducer/Consommation';
import { PagesReducer, PagesState } from './reducer/Pages';
import { PrixHubReducer, PrixHubState } from './reducer/PrixHub';
import { GazReducer, GazState } from './reducer/Gaz';
import { CapaciteReducer, CapaciteState } from './reducer/Capacite';
import { TransportReducer, TransportState } from './reducer/Transport';
import { TotsReducer, TotsOfferState, ResultReducer, ResultOfferState } from './reducer/Tots';
import { TaxesState, TaxesReducer } from './reducer/Taxes';

export interface GlobalOfferState {
  TypeOffre: TypeOffreState;
  Consommation: ConsommationState;
  Pages: PagesState;
  PrixHub: PrixHubState;
  Gaz: GazState;
  Capacite: CapaciteState;
  Transport: TransportState;
  Tots: TotsOfferState;
  Result: ResultOfferState;
  Taxes: TaxesState;
}

export const GlobalStore = createStore(
  combineReducers<GlobalOfferState>({
    TypeOffre: TypeOffreReducer,
    Consommation: ConsommationReducer,
    Pages: PagesReducer,
    PrixHub: PrixHubReducer,
    Gaz: GazReducer,
    Capacite: CapaciteReducer,
    Transport: TransportReducer,
    Tots: TotsReducer,
    Result: ResultReducer,
    Taxes: TaxesReducer,
  })
);
