import { IUpdateCapaciteAction, CapaciteAction } from '../action/Capacite';

const initialState: CapaciteState = {
  Column: [],
};

export interface ICapaciteParams {
  Wallbach: {
    CoeffTrim: number;
    CoeffMens: number;
    Tarif: {
      AnF: number;
      AnI: number;
    };
  };
  Lacure: {
    CoeffTrim: number;
    CoeffMens: number;
    Tarif: {
      AnF: number;
    };
  };
}

export interface ICapacite {
  MonthNumber: number;
  Month: string;
  Year: number;
  //param
  Params: ICapaciteParams;
  Data: {
    Wallbach: {
      Sell: {
        AnF: number;
        AnI: number;
        TrimF: number;
        TrimI: number;
        MensF: number;
        MensI: number;
      };
      Buy: {
        AnF: number;
        AnI: number;
        TrimF: number;
        TrimI: number;
        MensF: number;
        MensI: number;
      };
    };
    Lacure: {
      Sell: {
        AnF: number;
        TrimF: number;
        MensF: number;
      };
      Buy: {
        AnF: number;
        TrimF: number;
        MensF: number;
      };
    };
    SuppCap: number;
  };
  Discount: number;
}

export interface CapaciteState {
  Column: Array<ICapacite>;
}

export const CapaciteReducer = (state: CapaciteState = initialState, action: IUpdateCapaciteAction): CapaciteState => {
  switch (action.type) {
    case CapaciteAction.UPDATE_CAPACITE:
      return action.value;
    default:
      return state;
  }
};
