import { IUpdateConsommationAction, ConsommationAction } from '../action/Consommation';

const initialState: ConsommationState = {
  PCS: {
    Sell: 12.4,
    Buy: 11.4,
  },
  Column: [],
};

export interface ConsommationState {
  PCS: {
    Sell: number;
    Buy: number;
  };
  Column: Array<{
    MonthNumber: number;
    Month: string;
    Year: number;
    //param
    Hour: number;
    FeeCo2: number;
    FeeOil: number;
    FeeProvisiogas: number;
    PriceC: number;
    PriceCovercost: number;
    Data: {
      KWMonth: number;
      MWMonth?: number;
      HourlyPeak: number;
    };
  }>;
}

export const ConsommationReducer = (state: ConsommationState = initialState, action: IUpdateConsommationAction): ConsommationState => {
  const value = action.value;
  if (value?.Column) {
    value.Column.forEach(element => {
      element.Data.MWMonth = Number((Number(element.Data.KWMonth) / 1000).toFixed(3));
    });
  }
  switch (action.type) {
    case ConsommationAction.UPDATE_CONSO:
      return value;
    default:
      return state;
  }
};
