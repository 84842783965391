import { IUpdateTypeOffreAction, TypeOffreAction } from '../action/TypeOffre';

const getDate = (plusOne: boolean = false, year?: number): string => {
  let today = new Date();
  today.setMonth(today.getMonth() + 1);
  let dd = '01';
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  if (plusOne) {
    yyyy++;
  } else if (year) {
    yyyy += year;
  }

  return yyyy + '-' + mm + '-' + dd;
};

const initialState: TypeOffreState = {
  Type: 1,
  Reseau: {
    Nat: 1,
    Reg: 1,
    Loc: 1,
  },
  ClientName: 'John Doe',
  Dates: {
    Start: getDate(true),
    End: getDate(true),
  },
  Interuptable: false,
  Seasonal: false,
  FeeCo2: false,
  FeeOil: true,
  FeeProvisiogas: true,
  Until: "",
  Supp1: "",
  Supp2: "",
  Supp3: "",
};

export interface TypeOffreState {
  Type: number;
  Reseau: {
    Nat: number;
    Reg: number;
    Loc: number;
  };
  ClientName: string;
  Dates: {
    Start: string;
    End: string;
  };
  Interuptable: boolean;
  Seasonal: boolean;
  FeeCo2: boolean;
  FeeOil: boolean;
  FeeProvisiogas: boolean;
  Until: string;
  Supp1: string;
  Supp2: string;
  Supp3: string;
}

export const TypeOffreReducer = (state: TypeOffreState = initialState, action: IUpdateTypeOffreAction): TypeOffreState => {
  switch (action.type) {
    case TypeOffreAction.UPDATE_TYPE:
      return action.value;
    default:
      return state;
  }
};
