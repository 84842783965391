import { IAccessErrorAction, IUpdatePagesAction, PagesAction } from '../action/Pages';

const initialState: PagesState = {
  CurrentStep: 0,
  Step: '',
  isError: [false, false, true, false, false, false],
  AccessError: false,
};

export interface PagesState {
  CurrentStep: number;
  Step: string;
  isError: Array<boolean>;
  AccessError: boolean;
}
export const PagesReducer = (state: PagesState = initialState, action: IUpdatePagesAction | IAccessErrorAction): PagesState => {
  switch (action.type) {
    case PagesAction.UPDATE_PAGE:
      return action.value;
    case PagesAction.ACCESS_ERROR:
      return {
        ...state,
        AccessError: action.value,
      };
    default:
      return state;
  }
};
