import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import Loader from './components/loader/Loader';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { GlobalStore } from './store/root-reducer';

ReactDOM.render(
  <>
    <Suspense fallback={<Loader />}>
      <Provider store={GlobalStore}>
        <App />
      </Provider>
    </Suspense>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
