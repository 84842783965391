import { Middleware } from './Middleware';

export default class UserMiddleware extends Middleware {
  private static _ROLE = 'User';

  public static Run = async (): Promise<boolean> => {
    if (await Middleware.CheckConnection()) {
      UserMiddleware.CheckUserRole();
    }

    return Middleware.getAuthorization();
  };

  private static CheckUserRole = (): void => {
    const user = Middleware.getUser();
    Middleware.setAuthorization(user !== null && user !== undefined && user.roles.includes(UserMiddleware._ROLE));
  };
}
